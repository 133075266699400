import React, { FC } from 'react';
import './role-permission.styles.scss';
import Checkbox from '../../../checkbox/checkbox.component';

type Props = {
	title: string;
	dbValue: string;
	description: string;
	rolePermission: string;
	handleRoleSelection: (rolePermission: string) => void;
};

const RolePermission: FC<Props> = ({
	title,
	dbValue,
	description,
	rolePermission,
	handleRoleSelection,
}) => {
	return (
		<div className='role-permission-card'>
			<div className='role-permission-header-row'>
				<h3 className='role-permission-title'>{title}</h3>
						<Checkbox
							defaultChecked={
								rolePermission === dbValue
							}
							onChange={() => handleRoleSelection(dbValue)}
						/>
			</div>
			<p className='role-permission-description'>{description}</p>
		</div>
	);
};

export default RolePermission;
