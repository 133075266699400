import './external-settings-section.styles.scss';
import React, { FC } from 'react';
import editPencilIcon from '../../../assets/icons/external-portal/settings/edit-icon-grey.svg';
import deleteIcon from '../../../assets/icons/external-portal/settings/delete-icon.svg';
import infoIcon from '../../../assets/icons/external-portal/settings/info-grey.svg';
import { ExternalSettingsToolTip } from '../../../utils/externalUtilsFunction';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { StaffRole } from '../../../types/enum';
import { IStaffMember } from '../../../types/interfaces/staff.interface';

type Props = {
	sectionHeader: string,
	sectionData: IStaffMember[] | null,
	sectionIcon: string,
	sectionExplainer: string,
	handleDeleteStaff: (staffUser: IStaffMember) => void;
};

function getInitials(firstName: string, lastName: string) {
    const firstInitial = firstName[0].toUpperCase();
    const lastInitial = lastName[0].toUpperCase();
    return `${firstInitial}${lastInitial}`;
}

const ExternalSettingsSection: FC<Props> = ({
	sectionHeader,
	sectionData,
	sectionIcon,
	sectionExplainer,
	handleDeleteStaff,
}) => {
	const loggedInUserEmail = useSelector(
		(state: RootState) => state.auth.user.email,
	);
	const staffRole = useSelector(
		(state: RootState) => state.auth.role,
	);

	return (
	<div className='external-settings'>
		  <div className='external-settings-container'>
            <div className='external-settings-header'>
                <div className='external-settings-header-text'>
                    <span role='img' aria-label='admins' style={{ fontSize: '18px' }}>
						<img className='external-settings-role-icon' src={sectionIcon} />
					</span>
                    <p className='external-settings-title'>{sectionHeader}</p>
                </div>
                <span>
					<ExternalSettingsToolTip
						title={sectionExplainer}
					>
						<img src={infoIcon} />
					</ExternalSettingsToolTip>
				</span>
            </div>
            <table className='external-settings-table'>
                <tbody>
					{sectionData && sectionData.length > 0 ? (
						sectionData.map((staffUser, index) => (
							<tr key={staffUser.uuid} className='external-settings-tr'>
								<td className='external-settings-td'>
									<div className='external-settings-initials'>
										{getInitials(staffUser.name, staffUser.surname)}
									</div>
									<div>
										<div className='external-settings-name'>{`${staffUser.name} ${staffUser.surname}`}</div>
										<div className='external-settings-email'>{staffUser.email}</div>
									</div>
								</td>
								<td className='external-settings-td-right'>
									{/* <button
										onClick={() => handleEdit(role.id)}
										className='external-settings-edit-icon'
										aria-label='Edit'
									>
										<img src={editPencilIcon} />
									</button>
									*/}
									{staffRole === StaffRole.MANAGER && 
										loggedInUserEmail !== sectionData[index].email &&
										<button
											onClick={() => handleDeleteStaff(staffUser)}
											className='external-settings-delete-icon'
											aria-label='Delete'
										>
											<img src={deleteIcon}/>
										</button> 
									}
								</td>
                        </tr>
						))
					) : (
						<tr className='external-settings-tr'>
							<td className='external-settings-td'>
								<p className='external-settings-default-text'>{`None, click 'Add New User' to get started`}</p>
							</td>
						</tr>
					)}
                </tbody>
            </table>
        </div>
	</div>
	)
};

export default ExternalSettingsSection;

function dispatch(arg0: any) {
	throw new Error('Function not implemented.');
}
