export enum StaffRole {
	CONTROLLER = 'controller',
	SCREENER = 'screener',
	MANAGER = 'manager',
	ADMIN = 'admin',
	VIEWER = 'viewer',
}

export enum FormStateType {
	VOLUNTEER = 'volunteering',
	EMPLOYED = 'employed',
	STUDYING = 'studying',
	SELF_EMPLOYED = 'self-employed',
	JOB_SEEKING = 'job-seeking',
	GAP = 'gap',
	ANOTHER_REASON = 'another-reason',
}

// Right to Work Enums
export enum CheckType {
	RTW = 'rtw',
	BS7858 = 'bs7858',
	RTWBS7858 = 'rtwbs7858',
}

export enum RtwDocType {
	NONE = 'none',
	DIGITAL_PASSPORT = 'digital-passport',
	SHARE_CODE = 'share-code',
	ALL_OTHER_DOCS = 'document',
}