import React, { SetStateAction, Dispatch, FC } from 'react';
import AddStaffInfo from '../add-staff-info/add-staff-info.component';
import OrkaButton from '../../general/orka-button/orka-button.component';
import './add-staff-header.styles.scss';
import addStaffIcon from '../../../assets/icons/sidebar/add-staff-blue.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { StaffRole } from '../../../types/enum';

type Props = {
	setShowInviteModal: Dispatch<SetStateAction<boolean>>;
	setShowAddCandidateModal: Dispatch<SetStateAction<boolean>>;
};

const AddStaffHeader: FC<Props> = ({
	setShowInviteModal,
	setShowAddCandidateModal,
}) => {
	const user = useSelector((state: RootState) => state.auth.user);
	const role = useSelector((state: RootState) => state.auth.role);

	return (
		<div className='add-staff-header'>
			<div>
				<AddStaffInfo header={true} user={user} />
			</div>

			<div className='desktop-btn'>
				<div className='flex-buttons'>
					<OrkaButton
						buttonContent='Add Candidate'
						disabled={role === StaffRole.SCREENER}
						emitClicked={() => setShowAddCandidateModal(true)}
					/>
					<OrkaButton
						buttonContent='Add Staff'
						disabled={role === StaffRole.SCREENER}
						emitClicked={() => setShowInviteModal(true)}
					/>
				</div>
			</div>

			<div className='mobile-btn'>
				<div>
					<img
						src={addStaffIcon}
						onClick={() => setShowAddCandidateModal(true)}
						alt='add'
					/>
					{role === 'controller' && (
						<img
							src={addStaffIcon}
							onClick={() => setShowInviteModal(true)}
							alt='add'
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default AddStaffHeader;
