import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import './company-selection.styles.scss';
import { RootState } from '../../../../types/root-state'; 
import { IDropdownStandard } from '../../../../types/interface';
import { useSelector } from 'react-redux';
import './company-selection.styles.scss';
import { ICompanyItem } from '../../../../types/interfaces/company.interface';
import Dropdown from '../../../general/orka-dropdown/dropdown.component';

type Props = {
	getDropdownValue: Dispatch<SetStateAction<any>>;
	selectedCompany?: IDropdownStandard;
};
const defaultItems = [
	{
		id: 1,
		value: 'Select company',
		dbValue: 'company',
		class: 'dropdown-grey',
	},
];

const CompanySelection: FC<Props> = ({
	getDropdownValue,
	selectedCompany,
}) => {
	const [company, setCompany] = useState<IDropdownStandard[]>([]);
	const [defaultCompany, setDefaultCompany] = useState<IDropdownStandard>();
	const allCompanies = useSelector((state: RootState) => state.auth.company);
	useEffect(() => {
		const dropdownData = allCompanies.map(
			(company: ICompanyItem, index: number) => {
				return {
					id: defaultItems.length + index + 1,
					value: company.companyName,
					dbValue: company.companyUuid,
					class: 'dropdown-grey',
				};
			},
		);
		setDefaultCompany(defaultItems[0]);
		setCompany([...defaultItems, ...dropdownData]);
	}, []);
	useEffect(() => {
		if (selectedCompany) {
			setDefaultCompany(selectedCompany);
		}
	}, [selectedCompany]);

	return (
		<div className='company-invite-container'>
			{company.length > 0 && defaultCompany && (
				<div className='company-invite'>
					<Dropdown
						type=''
						dropdownData={company}
						defaultData={defaultCompany}
						getDropdownVal={getDropdownValue}
						width='200'
						mobileResponsive={false}
						position='bottom-right'
						height={24}
					/>
				</div>
			)}
		</div>
	);
};

export default CompanySelection;
