import './external-sidebar.styles.scss';

import React, { FC, useEffect } from 'react';
import { Tooltip } from '@mui/material';
import { withStyles } from 'mui-styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { useNavigate } from 'react-router-dom';
import { tabData } from '../../../utils/externalData';

import ExternalSearchContainer from '../external-search-container/external-search-container.component';

import arrowLeft from '../../../assets/icons/sidebar/arrow-left.svg';
import arrowRight from '../../../assets/icons/sidebar/arrow-right.svg';
import logoLarge from '../../../assets/logos/deploi-logo-white.svg';
import logoSmall from '../../../assets/logos/deploi-logo-sm-white.svg';
import logout from '../../../assets/icons/sidebar/logout.svg';
import searchIcon from '../../../assets/icons/search-white.svg';
import allUsers from '../../../assets/icons/sidebar/all-users.svg';
import allUsersSelected from '../../../assets/icons/sidebar/all-users-selected.svg';
import HelpIcon from '../../../assets/icons/sidebar/help-circle-white.svg';
import settings from '../../../assets/icons/external-portal/settings/settings-icon.svg';
import settingsSelected from '../../../assets/icons/external-portal/settings/settings-icon-selected.svg';

const TooltipLg = withStyles(() => ({
	tooltip: {
		fontSize: 14,
		fontWeight: 100,
		padding: '8px',
	},
}))(Tooltip);

type Props = {
	handleLogout: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const ExternalSidebar: FC<Props> = ({ handleLogout }) => {
	const navigate = useNavigate();

	const [openSideBar, setOpenSideBar] = React.useState(false);
	const [selectedState, setSelectedState] = React.useState(false);
	const [selectedStateSetting, setSelectedStateSetting] =
		React.useState(false);

	const user = useSelector((state: RootState) => state.auth.user);

	const tabStatusData = useSelector(
		(state: RootState) => state.dashboard.submissionStatusesTotal,
	);

	const handleToggleSidebar = () => {
		setOpenSideBar(!openSideBar);
	};

	const handleSelectCandidates = () => {
		setSelectedState(true);
		setSelectedStateSetting(false);
		navigate(`/check`);
	};

	const handleSelectSettings = () => {
		setSelectedState(false);
		setSelectedStateSetting(true);
		navigate(`/check/dashboard-settings`);
	};

	const checkUrl = () => {
		const path = window.location.pathname;
		// const pathname = path.split(/check?\//g);
		// const [, target] = pathname;

		if (path === '/check/dashboard-settings') {
			setSelectedStateSetting(true);
		} else if (path === '/check') {
			setSelectedState(true);
		}
	};

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > 1200) {
				setOpenSideBar(openSideBar);
			} else {
				setOpenSideBar(!openSideBar);
			}
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (window.innerWidth > 1200) {
			setOpenSideBar(openSideBar);
		} else {
			setOpenSideBar(!openSideBar);
		}
	}, []);

	useEffect(() => {
		checkUrl();
	}, []);

	const openHelp = () => {
		window.open(
			' https://intercom.help/deploi/en/collections/9332238-customer-help',
			'_blank',
			'noopener',
		);
	};
	return (
		<>
			<div
				className={
					openSideBar
						? `external-sidebar-container active`
						: `external-sidebar-container`
				}
			>
				{!openSideBar && user.name ? (
					<div className='sidebar-header-large'>
						<img
							src={logoLarge}
							alt='logo-large'
							onClick={() => {
								return navigate('/check');
							}}
						/>
						<div className='avatar'>{user.name[0]}</div>
					</div>
				) : (
					<div className='sidebar-header-small'>
						<img
							src={logoSmall}
							alt='logo-small'
							onClick={() => {
								return navigate('/check');
							}}
						/>
					</div>
				)}
				<div className='search-container'>
					{!openSideBar ? (
						<ExternalSearchContainer />
					) : (
						<div className='small-search'>
							<TooltipLg title='Search'>
								<img
									src={searchIcon}
									alt='search'
									onClick={handleToggleSidebar}
								/>
							</TooltipLg>
						</div>
					)}
				</div>

				<div className='inner-sidebar-container'>
					<div className='inner-sidebar-info-container border-line '>
						{!openSideBar ? (
							<div
								className='inner-sidebar-item'
								onClick={() => handleSelectCandidates()}
							>
								<div className='inner-sidebar-item-info'>
									<img 
										src={
											selectedStateSetting
												? allUsers
												: allUsersSelected
										}
										alt='candidates' 
									/>
									<p
										className={
											selectedState
												? 'primary-link-text'
												: 'link-text'
										}
									> Candidates </p>
								</div>
								<div className='inner-sidebar-amount'>
									<p>
										{(tabStatusData &&
											tabStatusData[0] &&
											tabStatusData[0].total) ||
											(tabData &&
												tabData[0] &&
												tabData[0].total)}
									</p>
								</div>
							</div>
						) : (
							<div>
								<TooltipLg title={`${'Candidates'}`}>
									<div
										className='inner-sidebar-item-sm'
										onClick={() => handleSelectCandidates()}
									>
										<img
											src={
												selectedState
													? allUsersSelected
													: allUsers
											}
											alt='allCandidates'
										/>
									</div>
								</TooltipLg>
							</div>
						)}
					</div>
				</div>

				{!openSideBar && (
					<>
						<div
							className='sidebar-action-lg'
							onClick={handleSelectSettings}
							role='link'
						>
							<img
								src={
									selectedStateSetting
										? settingsSelected
										: settings
								}
								alt='settings'
							/>
							<p
								className={
									selectedStateSetting
										? 'blue-link-text'
										: 'link-text'
								}
							>
								Settings
							</p>
						</div>

						<div
							className='sidebar-action-lg'
							onClick={openHelp}
							role='link'
						>
							<img src={HelpIcon} alt='Help' />
							<p className='link-text'>Help</p>
						</div>
						<div
							className='sidebar-action-lg'
							onClick={handleLogout}
							role='link'
						>
							<img src={logout} alt='logout' />
							<p className='logout-txt'>Logout</p>
						</div>
					</>
				)}

				{openSideBar && (
					<>
						<div className='sidebar-action-sm'>
							<TooltipLg title='Settings'>
								<img
									src={
										selectedStateSetting
											? settingsSelected
											: settings
									}
									alt='Settings'
									onClick={handleSelectSettings}
								/>
							</TooltipLg>
						</div>

						<div className='sidebar-action-sm'>
							<TooltipLg title='Help' role='link'>
								<img
									src={HelpIcon}
									alt='Help'
									onClick={openHelp}
								/>
							</TooltipLg>
						</div>
						<div className='sidebar-action-sm'>
							<TooltipLg title='Logout' role='link'>
								<img
									src={logout}
									alt='logout'
									onClick={handleLogout}
								/>
							</TooltipLg>
						</div>
					</>
				)}

				<div className='spacer'></div>
				<div className='toggle'>
					<button
						onClick={handleToggleSidebar}
						className='toggle-sidebar'
					>
						<img
							src={!openSideBar ? arrowLeft : arrowRight}
							alt='open toggle'
						/>
					</button>
				</div>
			</div>
		</>
	);
};

export default ExternalSidebar;
