import './staff-action-modal.styles.scss';

import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import EditInput from '../../general/edit-input/input.component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { IDropdownStandard } from '../../../types/interface';
import { StaffRole } from '../../../types/enum';
import { IInvitePayload } from '../../../types/interfaces/auth.interface';
import RolePermission from './role-permission/role-permission.component';
import { setAlert } from '../../../redux/General/general.actions';
import CompanySelection from './company-selection/company-selection.component';
import Modal from '../modal/modal.component';
import cross from '../../../assets/icons/cross.svg';
import { invite } from '../../../api/api.auth';
import { deleteStaff } from '../../../api/api.staff';

interface Props {
	modalTitle: string;
	userDetails: any;
	setUserDetails: any;
	modalActionButton: string;
	showModal: boolean;
	setShowModal: Dispatch<SetStateAction<boolean>>;
	staffDeletionUuid: string;
	fetchStaff: (groupByOption?: string) => void;
};

const StaffActionModal: FC<Props> = ({
	modalTitle,
	userDetails,
	setUserDetails,
	modalActionButton,
	showModal,
	setShowModal,
	staffDeletionUuid,
	fetchStaff,
}) => {
	const dispatch = useDispatch();
	const role = useSelector(
		(state: RootState) => state.auth.role,
	);
	const [rolePermission, setRolePermission] = React.useState('');
	const [selectedCompany, setSelectedCompany] = useState<IDropdownStandard>();
	
	const handleInputChange = (val: string, input: string) => {
		setUserDetails({ ...userDetails, [input]: val });
	};

	const handleRoleSelection = (rolePermission: string) => {
		setRolePermission(rolePermission);
	};

	const handleFormSubmit = async () => {
		if (modalActionButton === 'Add' || modalActionButton === 'Invite') {
			const invitePayload: IInvitePayload = {
				...userDetails,
				role: rolePermission,
			};
			if (role === StaffRole.CONTROLLER) {
				if (!selectedCompany || selectedCompany?.value === 'Select company') {
					dispatch(
						setAlert({
							type: 'error',
							message: 'Please select a company from the dropdown',
							isVisible: true,
						}),
					);
					return;
				}
				invitePayload.companyUuid = selectedCompany?.dbValue;
			}
			
			handleSubmit(invitePayload);
		} else if (modalActionButton === 'Edit') {
			// edit staff user functionality
		}
	};

	const canSubmit = () => {
		const isEmailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(userDetails.email);
		const isValidSelection = () => {
			if (role === StaffRole.CONTROLLER) {
				if (selectedCompany?.value !== 'Deploi' &&
					rolePermission === 'Controller' ||
					rolePermission === 'Screener'
				) {
					return false;
				}
			}
			return true;
		};
		return (
		  userDetails.name !== '' &&
		  userDetails.surname !== '' &&
		  userDetails.email !== '' &&
		  isEmailValid && 
		  isValidSelection()		);
	};

	const handleSubmit = async (userDetails: IInvitePayload) => {
		await invite(userDetails)
			.then(() => {
				dispatch(
					setAlert({
						type: 'success',
						message: 'User successfully added',
						isVisible: true,
					}),
				);
				fetchStaff();
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.data.message,
						isVisible: true,
					}),
				);
			});
			setShowModal(false);
			setUserDetails({
				name: '',
				surname: '',
				email: '',
			});
	};
	
	const handleDelete = async () => {
		await deleteStaff(staffDeletionUuid)
			.then(() => {
				fetchStaff();
				dispatch(
					setAlert({
						type: 'success',
						message: 'User successfully deleted',
						isVisible: true,
					}),
				);
			})
			.catch(() => {
				dispatch(
					setAlert({
						type: 'error',
						message: 'Failed to delete user',
						isVisible: true,
					}),
				);
			})
		setUserDetails({
			name: '',
			surname: '',
			email: '',
		});
		setShowModal(false);
	};

	const renderAddStaffUser = () => {
		return (
			<Modal
				title={modalTitle}
				setShowModal={setShowModal}
				showModal={showModal}
				setUserDetails={setUserDetails}
			>
				<div className='staff-action-modal-content'>			
					{role === StaffRole.CONTROLLER && (
						<div className='company-selection'>
							<CompanySelection
								selectedCompany={selectedCompany}
								getDropdownValue={setSelectedCompany}
							/>
						</div>
					)}
					<div className='form-container'>
						<form className='form-content'>
							<div className='form-group'>
								<div className='text-input'>
									<EditInput
										inputName='name'
										placeholder='First Name'
										value={userDetails.name}
										title=''
										emitValue={handleInputChange}
									/>
								</div>
								<div className='text-input'>
									<EditInput
										inputName='surname'
										placeholder='Surname'
										value={userDetails.surname}
										title=''
										emitValue={handleInputChange}
									/>
								</div>
							</div>
							<div className='text-input'>
								<EditInput
									inputName='email'
									placeholder='Enter email address'
									value={userDetails.email}
									title=''
									emitValue={handleInputChange}
								/>
							</div>
						</form>
	
						<div className='permission-container'>
							{role === StaffRole.CONTROLLER && (
								<>
									<RolePermission
										title='Internal: Controller'
										dbValue='Controller'
										description='Controllers will have full access to the platform. 
											As well as the ability to invite, edit and remove staff.'
										rolePermission={rolePermission}
										handleRoleSelection={handleRoleSelection}
									/>
									<RolePermission
										title='Internal: Screener'
										dbValue='Screener'
										description='Screeners will have full access to the platform. 
											But they will not have the ability to invite, edit and remove staff.'
										rolePermission={rolePermission}
										handleRoleSelection={handleRoleSelection}
									/>
								</>
							)}
							<RolePermission
								title='Manager (Full Access)'
								dbValue='Manager'
								description='Managers will receive all emails and have full access to the platform. 
									They also have the ability to invite, edit, and remove staff members.'
								rolePermission={rolePermission}
								handleRoleSelection={handleRoleSelection}
							/>
							<RolePermission
								title='Admin'
								dbValue='Admin'
								description='Admins will only receive emails for areas they have been granted access to. 
									They can view the platform but do not have full management permissions.'
								rolePermission={rolePermission}
								handleRoleSelection={handleRoleSelection}
							/>
							<RolePermission
								title='Viewer'
								dbValue='Viewer'
								description='Viewers can access all the same areas and information as other roles but are
									restricted from taking any actions.'
								rolePermission={rolePermission}
								handleRoleSelection={handleRoleSelection}
							/>
						</div>
					</div>
					<div className='form-footer'>
						<button className='form-button' onClick={handleCloseModal}>
							Cancel
						</button>
						<button
							className='form-button primary'
							onClick={handleFormSubmit}
							disabled={!canSubmit()}
						>
							{modalActionButton}
						</button>
					</div>
				</div>
			</Modal>
		)
	};

	const renderDeleteStaffUser = () => {
		return (
			<div className='staff-action-modal-delete-content'>
				<div className='staff-action-modal-delete-header'>
					<img
						onClick={handleCloseModal}
						alt='close'
						src={cross}
					/>
				</div>
				<div className='staff-action-modal-delete-message'>
					<h2 className='staff-action-modal-delete-title'>
						Are you sure you want to delete
					</h2>
					<h2 className='staff-action-modal-delete-name'>
						{userDetails.name} {userDetails.surname}?
					</h2>
					<p className='staff-action-modal-delete-explainer'>
						You cannot undo the deletion of a staff member. However,
						this won&apos;t impact the audit information
					</p>
				</div>
				<div className='staff-action-modal-delete-button'>
					<button
						className='delete-button'
						onClick={handleDelete}
					>
						Yes, delete
					</button>
				</div>
			</div>
		)
	};

	const handleCloseModal = () => {
		setShowModal(false);
		setUserDetails({
			name: '',
			surname: '',
			email: '',
		});
	};

	return (
		<div className='staff-action-modal-container'>
			{modalActionButton !== 'Delete' ? renderAddStaffUser() : 
				renderDeleteStaffUser()}
		</div>
	);
};

export default StaffActionModal;

