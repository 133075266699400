import './external-settings-container.styles.scss';
import React, { FC, useEffect, useState } from 'react';
import ExternalSettingsHeader from '../external-settings-header/external-settings-header.component';
import ExternalSettingsSection from '../external-settings-section/external-settings-section.component';
import managerIcon from '../../../assets/icons/external-portal/settings/user-single.svg';
import adminIcon from '../../../assets/icons/external-portal/settings/user-multiple.svg';
import viewerIcon from '../../../assets/icons/external-portal/settings/eye-grey.svg';
import { getExternalSettingsExplainer } from '../../../utils/externalUtilsFunction';
import { IStaffMember } from '../../../types/interfaces/staff.interface';

type Props = {
	handleAddStaff: (e: any) => void;
	handleDeleteStaff: (staffUser: IStaffMember) => void;
	managerData: any;
	adminData: any;
	viewerData: any;
};

const ExternalSettingsContainer: FC<Props> = ({
	handleAddStaff,
	handleDeleteStaff,
	managerData,
	adminData,
	viewerData
}) => {

	return (
		<div className='external-setting-container'>
			<ExternalSettingsHeader handleAddStaff={handleAddStaff}/>
			<div className='external-settings-sections'>
				<ExternalSettingsSection
					sectionHeader={'Managers'}
					sectionData={managerData}
					sectionIcon={managerIcon}
					sectionExplainer={getExternalSettingsExplainer('Manager')}
					handleDeleteStaff={handleDeleteStaff}
				/>
				<ExternalSettingsSection
					sectionHeader={'Admins'}
					sectionData={adminData}
					sectionIcon={adminIcon}
					sectionExplainer={getExternalSettingsExplainer('Admin')}
					handleDeleteStaff={handleDeleteStaff}
				/>
				<ExternalSettingsSection
					sectionHeader={'Viewers'}
					sectionData={viewerData}
					sectionIcon={viewerIcon}
					sectionExplainer={getExternalSettingsExplainer('Viewer')}
					handleDeleteStaff={handleDeleteStaff}
				/>
			</div>
		</div>
	)
};

export default ExternalSettingsContainer;