import React, { useState, FC } from 'react';
import './add-staff.styles.scss';
import AddStaffHeader from '../../components/settings/add-staff-header/add-staff-header.component';
import ErrorBoundary from '../../components/general/error-boundary/error-boundary.component';
import Modal from '../../components/modals/modal/modal.component';
import Layout from '../../components/general/layout-component/layout.component';
import AddStaffRow from '../../components/settings/add-staff-row/add-staff-row.component';
import { useSelector } from 'react-redux';
import { RootState } from '../../types/root-state';
import AddCandidateModal from '../../components/settings/add-candidate-modal/add-candidate-modal.component';
import SetDefaultFlow from '../../components/settings/set-default-flow/set-default-flow.component';
import StaffActionModal from '../../components/modals/staff-action-modal/staff-action-modal.component';
import { setAllStaff } from '../../redux/Auth/auth.actions';
import { IStaffMember } from '../../types/interfaces/staff.interface';
import { getAllStaff } from '../../api/api.staff';
import { useDispatch } from 'react-redux';

const AddStaff: FC = () => {
	const dispatch = useDispatch();
	const [showInviteModal, setShowInviteModal] = useState(false);
	const [showAddCandidateModal, setShowAddCandidateModal] = useState(false);
	const [userDetails, setUserDetails] = React.useState({
		name: '',
		surname: '',
		email: '',
	});
	const allStaff = useSelector((state: RootState) => state.auth.staff);
	const role = useSelector((state: RootState) => state.auth.role);
	const fetchStaff = () => {
		getAllStaff()
			.then((res) => {
				const found = res.data.data.find(
					(element: IStaffMember) => element.email === userDetails.email,
				);
				const filteredArray = res.data.data.filter(
					(element: IStaffMember) => element.email !== userDetails.email,
				);
				dispatch(
					setAllStaff(
						handleAssignColor([found, ...filteredArray]),
					),
				);
		});

		setUserDetails({
			name: '',
			surname: '',
			email: '',
		});
	}
	const handleAssignColor = (staffArray: IStaffMember[]) => {
		const colors = [
			'color-green',
			'color-blue',
			'color-orange',
			'color-purple ',
			'color-red',
		];

		return staffArray.map((member: IStaffMember, index: number) => {
			member.color = colors[index % colors.length];
			member.selected = false;
			return member;
		});
	};
	return (
		<Layout>
			<ErrorBoundary applicantWorkerUuid='None'>
				<div className='settings-container'>
					<AddStaffHeader
						setShowInviteModal={setShowInviteModal}
						setShowAddCandidateModal={setShowAddCandidateModal}
					/>
					<div className='staff-rows'>
						{allStaff.length > 0 && (
							<>
								<div className='staff-added'>
									<p>Staff added</p>
								</div>
								{allStaff.map((staff) => {
									return (
										<div key={staff.uuid}>
											<AddStaffRow staffMember={staff} />
										</div>
									);
								})}
							</>
						)}
					</div>
					{role === 'controller' && <SetDefaultFlow />}
				</div>

				{showInviteModal && (
					<StaffActionModal
						modalTitle='Invite Staff Member'
						userDetails={userDetails}
						setUserDetails={setUserDetails}
						modalActionButton='Invite'
						showModal={showInviteModal}
						setShowModal={setShowInviteModal}
						staffDeletionUuid={''}
						fetchStaff={fetchStaff}
					/>
				)}
				{showAddCandidateModal && (
					<Modal
						title='Invite a Candidate'
						setShowModal={setShowAddCandidateModal}
						showModal={showAddCandidateModal}
					>
						<AddCandidateModal
							setShowModal={setShowAddCandidateModal}
						/>
					</Modal>
				)}
			</ErrorBoundary>
		</Layout>
	);
};

export default AddStaff;