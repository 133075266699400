import './checkbox.styles.scss';
import React, { CSSProperties, FC } from 'react';

interface Props {
	label?: string;
	defaultChecked?: boolean;
	disabled?: boolean;
	onChange: () => void;
	style?: CSSProperties;
}

const Checkbox: FC<Props> = ({
	label,
	defaultChecked,
	onChange,
	disabled,
	style,
}) => {
	return (
		<div className='checkbox-container'>
			{label && <label>{label}</label>}
			<div className='checkbox-control'>
				<input
					type='checkbox'
					checked={defaultChecked}
					onChange={onChange}
					disabled={disabled}
					style={style}
				/>
			</div>
		</div>
	);
};

export default Checkbox;
