import { axiosInstance } from './api.axiosInstance';
import { IStaffMember } from '../types/interfaces/staff.interface';

export const getAllStaff = (
	groupByOption?: string
): Promise<any> => {
	const url = `user/staff${groupByOption ? `?groupByOption=${groupByOption}` : ''}`;
	return axiosInstance()
		.get(url)
		.catch((err) => {
			throw err;
		});
};

export const assignStaffMember = (
	uuid: string,
	payload: Partial<IStaffMember>,
): Promise<any> => {
	return axiosInstance()
		.post(`/admin/applicants/${uuid}/assign`, payload)
		.then(({ data }) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};

export const unAssignStaffMember = (uuid: string): Promise<any> => {
	return axiosInstance()
		.delete(`/admin/assign/${uuid}`)
		.then((data) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};

export const deleteStaff = (uuid: string): Promise<any> => {
	return axiosInstance()
		.delete(`/user/staff/${uuid}`)
		.then((data) => {
			return data;
		})
		.catch((err) => {
			throw err;
		});
};
