import { useSelector } from 'react-redux';
import './external-settings-header.styles.scss';
import React, { FC, useState } from 'react';
import { RootState } from '../../../types/root-state';
import { StaffRole } from '../../../types/enum';

type Props = {
	handleAddStaff: (e: any) => void;
}

const ExternalSettingsHeader: FC<Props>  = ({handleAddStaff}) => {
	const role = useSelector(
		(state: RootState) => state.auth.role,
	);
	return (
		<div className='external-settings-header'>
			<div className='external-settings-header-left'>
				<h2 className='external-settings-header-title'>Team Users</h2>
			</div>
			<div className='external-settings-header-right'>
				{role === StaffRole.MANAGER &&
					<button 
						className='external-settings-add-button'
						onClick={handleAddStaff}
					>Add New User</button>
				}
			</div>
		</div>
	)
};

export default ExternalSettingsHeader;