import './external-check-container.styles.scss';

import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { clearAuth, setAllStaff } from '../../../redux/Auth/auth.actions';
import { clearProfileData } from '../../../redux/Profile/profile.actions';
import { setAlert, setIsLoading } from '../../../redux/General/general.actions';
import { RootState } from '../../../types/root-state';
import {
	resetDashboardState,
	setApplicants,
	setSubmissionStatusesTotal,
	setTotalPages,
} from '../../../redux/Dashboard/dashboard.actions';
import { IStaffMember } from '../../../types/interfaces/staff.interface';

import * as authApi from '../../../api/api.auth';
import * as apiStaff from '../../../api/api.staff';
import * as apiExternal from '../../../api/api.external';

import ExternalDashboard from '../../../pages/External-dashboard/external-dashboard.component';
import ExternalSidebar from '../external-sidebar/external-sidebar.component';
import ExternalSettings from '../../../pages/External-settings/external-settings.component';
import ExternalUserProfile from '../../../pages/External-user-profile/external-user-profile.component';
import NotFound from '../../general/not-found/not-found.component';
import DownloadableReport from '../../../pages/Downloadable-report/downloadable-report.component';
import ClearanceCertificatePage from '../../../pages/Clearance-certificate/clearance-certificate.component';
import ErrorBoundary from '../../general/error-boundary/error-boundary.component';

const ExternalCheckContainer: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	//const [isLoggedOut, setIsLoggedOut] = useState<boolean>(false);

	const handleLogout = () => {
		authApi
			.logout()
			.then((res) => {
				dispatch(
					setAlert({
						type: res && res.status === 201 ? 'info' : 'error',
						message:
							res && res.status === 201
								? 'Successfully logged out'
								: 'Unable to log out. Please clear your cookies and close your browser.',
						isVisible: true,
					}),
				);
				return;
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
			});
		//setIsLoggedOut(true);
		dispatch(clearProfileData());
		//console.warn(isLoggedOut);
		dispatch(resetDashboardState());
		dispatch(clearAuth());
		navigate(`/login`, { replace: true });
	};

	const searchTerm = useSelector(
		(state: RootState) => state.dashboard.searchTerm,
	);
	const sortBy = useSelector((state: RootState) => state.dashboard.sortBy);
	const searchBy = useSelector(
		(state: RootState) => state.dashboard.searchBy,
	);
	const page = useSelector((state: RootState) => state.dashboard.page);
	const sortDirection = useSelector(
		(state: RootState) => state.dashboard.sortDirection,
	);
	const refreshGetApplicants = useSelector(
		(state: RootState) => state.dashboard.refreshGetApplicants,
	);
	const submissionStatusTab = useSelector(
		(state: RootState) => state.dashboard.submissionStatusTab,
	);

	const role = useSelector((state: RootState) => state.auth.role);
	const user = useSelector((state: RootState) => state.auth.user);

	if (role === 'screener' || role === 'controller' || role === 'ambassador') {
		return null;
	}

	useEffect(() => {
		const fetchData = (): void => {
			dispatch(setIsLoading(true));
			apiExternal
				.getExternalApplicants(
					searchTerm,
					sortBy,
					searchBy,
					page,
					sortDirection,
					submissionStatusTab,
				)
				.then(({ data }) => {
					const total = data.meta.total;
					const totalPages = Math.ceil(total / 20);
					dispatch(setIsLoading(false));
					dispatch(setApplicants(data.items));
					dispatch(setTotalPages(totalPages));
				})
				.catch(() => {
					dispatch(setIsLoading(false));
					dispatch(
						setAlert({
							type: 'error',
							message: 'Unable to get applicants',
							isVisible: true,
						}),
					);
				});
		};
		fetchData();
	}, [
		role,
		searchTerm,
		sortBy,
		searchBy,
		page,
		dispatch,
		refreshGetApplicants,
		sortDirection,
		submissionStatusTab,
	]);

	useEffect(() => {
		apiExternal
			.getSubmissionStatuses()
			.then(({ data }) => {
				if (data.status === 404) {
					throw data;
				} else {
					dispatch(setSubmissionStatusesTotal(data));
				}
			})
			.catch((err) => {
				throw err;
			});
	}, [refreshGetApplicants, dispatch]);

	useEffect(() => {
		apiStaff
			.getAllStaff()
			.then((res) => {
				if (res.status === 404) {
					throw res;
				} else {
					const found = res.data.data.find(
						(element: IStaffMember) => element.email === user.email,
					);
					const filteredArray = res.data.data.filter(
						(element: IStaffMember) => element.email !== user.email,
					);
					dispatch(setAllStaff([found, ...filteredArray]));
				}
			})
			.catch((err) => {
				throw err;
			});
	}, []);

	return (
		<div className='external-check-container'>
			<ErrorBoundary applicantWorkerUuid='None'>
				<ExternalSidebar handleLogout={handleLogout} />
			</ErrorBoundary>
			<Routes>
				<Route path='/' element={<ExternalDashboard />} />
				<Route path='/user/:id' element={<ExternalUserProfile />} />
				<Route
					path='/dashboard-settings'
					element={<ExternalSettings />}
				/>
				<Route
					path='/certificate'
					element={<ClearanceCertificatePage />}
				/>
				<Route path='/report' element={<DownloadableReport />} />
				<Route
					path='*'
					element={<NotFound type='noData' pageNotFound={true} />}
				/>
			</Routes>
		</div>
	);
};

export default ExternalCheckContainer;
